$bowline-gray: #373A3F;
$bowline-green: #00c3a5;
$bowline-dark-blue: #0C0E16;
$wd-bg-gradient-pink: linear-gradient(180deg, #38c4bd 0%, #ff1f70 100%);
$wd-bg-gradient-dark: linear-gradient(180deg, #08090a 0%, #26292e 100%);
$wd-red: #9d294c;
$wd-highlight-pink: #ff1f70;
$wd-off-white: #fff;

$letter-spacing-xs: 0.01em;
$letter-spacing-s: 0.02em;
$letter-spacing-m: 0.05em;
$letter-spacing-l: 0.1em;
$letter-spacing-xl: 0.13em;
$letter-spacing-xxl: 0.14em;

$letter-spacing-big: 0.2em;
$letter-spacing-button: 0.23em;
