// font from CDN
// @import url("https://fonts.googleapis.com/css?family=Open+Sans:400,300,600,700")

///mixins
@import "styles/mixins/fontface";

//fonts
@include fontFace('rockwell', '/assets/fonts/rockwell');
@include fontFace('Poppins Bold', '/assets/fonts/poppins-v20-latin-900');
@include fontFace('Poppins', '/assets/fonts/poppins-v20-latin-regular');

// theme
@import "styles/prebootstrapvariables";
@import "../node_modules/bootstrap/scss/functions";
@import "styles/bootstrapvariables";
@import "styles/variables";

// vendor modules
@import "../node_modules/bootstrap/scss/bootstrap";

// stylesheets containers
@import "styles/containers/Root";
@import "styles/containers/App";
@import "styles/containers/Landingpage";
@import "styles/containers/Dashboard";
@import "styles/containers/Account";
@import "styles/containers/FourOFour";

//stylesheets components
@import "components/About/About";
@import "components/FieldInput/FieldInput";
@import "components/FileInput/FileInput";
@import "components/LoadingContent/LoadingContent";
@import "components/Navbar/Navbar";
@import "components/Register/Register";
@import "components/LandingpageHeroImage/LandingpageHeroImage";
@import "components/OnBoardingContent/OnBoardingContent";

body {
  margin: 0;
  padding: 0;
  background-color: $secondary;
  //font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

h4, h5, h6, .h4, .h5, .h6, p, .p {
  font-family: Poppins; }

.opacity-50 {
  opacity: 50%; }

h1, h2, h3, .h1, .h2, .h3 {
  font-family: Poppins Bold; }

.btn-circle-float {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  z-index: 999;
  i {
    transform: translateY(15%); } }

.cursor-pointer {
  cursor: pointer; }

.btn-outline-primary, .btn-primary, .btn-secondary {
  text-transform: capitalize;
  font-weight: bold;
  &:hover {
    box-shadow: 4px 4px 0px 0px $wd-red;
    transform: translateY(-5px) translateX(-5px);
    transition: ease-in-out 0.3s;
    color: white; } }

.btn-outline-primary {
  background: transparent;
  border: 2px solid $wd-highlight-pink;
  &:hover {
    background: $wd-highlight-pink; } }

.btn-outline-nav {
  background: transparent;
  border: 2px solid $light;
  text-transform: capitalize;
  font-weight: bold;
  &:hover {
    background: $wd-highlight-pink;
    box-shadow: 4px 4px 0px 0px $wd-red;
    transform: translateY(-5px) translateX(-5px);
    transition: ease-in-out 0.3s;
    border: none; }
  &:active {
    background-color: transparent !important; } }
